import { db } from "./firebaseconfig.js"
import { useEffect, useState,} from "react"
import {getDocs, collection} from "firebase/firestore"
import {Link} from "react-router-dom"
import { useParams } from 'react-router-dom';
import instaIcon from "./assets/icons8-instagram-150.png"
import googleIcon from "./assets/icons8-google-150.png"
import logo from "./assets/unnameda.png"

function Reslist() {

    const [items, setItems] = useState([]);
    
    

    useEffect(() =>{
        const citiesRef = collection(db, "menu")

        const getUsers = async () => {
          const data = await getDocs(citiesRef);
          setItems(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }
        getUsers()
      }, [])

      const handleClick = () => {
        const url = 'https://goo.gl/maps/bQCsp3MLAnb6KDzK7'; // Hier den gewünschten Link einfügen
        const win = window.open(url, '_blank');

      };

      const handleClick1 = () => {
        const url = 'https://instagram.com/classic_idstein?igshid=MzRlODBiNWFlZA=='; // Hier den gewünschten Link einfügen
        const win = window.open(url, '_blank');
       
      };


    return(
        <div className="">
            <div className="flex justify-center">
            <img className=" m-5 w-96" src={logo}></img>
            </div>
            <div className="flex justify-center text-xl">HERZLICH WILLKOMMEN</div>
            <div className="flex justify-center text-4xl p-3 text-yellow-500  ">- MENU -</div>
            <div className="flex justify-center font-bold">
            <div className="p-2 m-2 bg-gradient-to-r from-pink-500 to-yellow-500 rounded-lg flex " onClick={handleClick1}><img className="h-6 w-6 mr-1" src={instaIcon}></img><div>INSTAGRAM</div></div>
            <div className="p-2 m-2 bg-gradient-to-r from-green-500 to-green-700 rounded-lg flex " onClick={handleClick}> <img className="h-6 w-6 mr-1" src={googleIcon}></img><div>BEWERTEN</div></div>
            </div>

            <div className="md:grid md:grid-cols-2 ">
            
            {items.map((item) =>{
                return <div className="m-8 md:m-6  border-2 border-zinc-800 rounded-2xl ">
                <Link to={`/${item.id}`} className="">
                <img className="rounded-2xl" src={item.img}></img>
                </Link>
                </div>
            })}
            </div>

        </div>
    )
}


export default Reslist;