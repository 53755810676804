import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyCEvQcrnv0xqfguhMsMVQOB5aihshu-f-Q",
  authDomain: "classic-speisekarte.firebaseapp.com",
  projectId: "classic-speisekarte",
  storageBucket: "classic-speisekarte.appspot.com",
  messagingSenderId: "162106134384",
  appId: "1:162106134384:web:3682d41d85b68b793ac9b5",
  measurementId: "G-3VFZ3MY337"
  };

  const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);